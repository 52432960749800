import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';
import _ from 'lodash';
import Splitting from 'splitting';


const bindFilling = (sliderContainer, index, count) => {
  const strip = sliderContainer.querySelector('[data-line]');
  if (strip) {
    const stripWidth = strip.offsetWidth;
    const stripFilling = strip.querySelector('[data-line-filling]');
    stripFilling.style.width = `${stripWidth * index / count}px`;
  }
};

const sliderGoTo = (slider, slide) => {
  slider.goTo(slide);
};

const goToThrottle = _.throttle(sliderGoTo, 2000);

function bindArrows(left, right, slider, sliderContainer, mainSlider) {
  const leftArrow = sliderContainer.querySelector(left);
  const rightArrow = sliderContainer.querySelector(right);
  if (leftArrow) {
    leftArrow.addEventListener('click', () => {
      if (mainSlider) {
        goToThrottle(slider, 'prev');
      } else {
        slider.goTo('prev');
      }
    });
  }

  if (rightArrow) {
    rightArrow.addEventListener('click', () => {
      if (mainSlider) {
        goToThrottle(slider, 'next');
      } else {
        slider.goTo('next');
      }
    });
  }
}

const addZero = (slider, sliderContainer) => {
  const slideIndexText = sliderContainer.querySelector('[data-slider-index]');
  const slidesCountText = sliderContainer.querySelector('[data-slider-count]');
  const info = slider.getInfo();
  const slideIndex = info.displayIndex;
  const slidesCount = info.slideCount;

  if (slideIndexText) {
    slideIndexText.innerHTML = slideIndex < 10 ? `0${slideIndex}` : slideIndex;
  }
  if (slidesCountText) {
    slidesCountText.innerHTML = slidesCount < 10 ? `0${slidesCount}` : slidesCount;
  }
  bindFilling(sliderContainer, slideIndex, slidesCount);
};

const defaultSlider = (sliderContainer, sliderInner, left, right, speed = 1300) => {
  if (sliderContainer) {
    const defaultSliderInner = sliderContainer.querySelector(sliderInner);
    if (defaultSliderInner) {
      const slider = tns({
        container: defaultSliderInner,
        nav: false,
        items: 1,
        controls: false,
        speed: 1300,
        mode: 'gallery',
        animateIn: 'animate_in',
        animateOut: 'animate_out',
        animateNormal: 'normal',
        rewind: false,
        autoplayButtonOutput: false,
        preventActionWhenRunning: true,
      });

      if (!slider) return;

      setTimeout(() => {
        addZero(slider, sliderContainer);
      }, 300);

      slider.events.on('transitionStart', () => {
        addZero(slider, sliderContainer);
      });

      bindArrows(left, right, slider, sliderContainer);
    }
  }
};

const sliderImages = (sliderContainer, sliderInner, left, right) => {
  const defaultSliderInner = sliderContainer.querySelector(sliderInner);
  if (defaultSliderInner) {
    const slider = tns({
      container: defaultSliderInner,
      nav: false,
      autoWidth: true,
      controls: false,
      edgePadding: 11,
      rewind: false,
      preventActionWhenRunning: false,
      mouseDrag: true,
      slideBy: 'page',
      responsive: {
        0: {
          gutter: 20,
          items: 1.3,
          speed: 400,
        },
        768: {
          items: 1.8,
          gutter: 25,
          speed: 1500,
        },
        1200: {
          items: 2.3,
          gutter: 55,
          viewportMax: 1200,
          speed: 1500,
        },
      },
    });

    if (!slider) return;

    setTimeout(() => {
      addZero(slider, sliderContainer);
    }, 300);

    slider.events.on('transitionStart', () => {
      addZero(slider, sliderContainer);
    });

    try {
      bindArrows(left, right, slider, sliderContainer);
    } catch (e) {
    }
  }
};

const mainSlider = (sliderContainer, sliderInner, left, right) => {
  const mainSliderInner = sliderContainer.querySelector(sliderInner);
  if (mainSliderInner) {
    const slider = tns({
      container: mainSliderInner,
      nav: false,
      items: 1,
      controls: false,
      speed: 1500,
      mode: 'gallery',
      animateIn: 'animate_in',
      animateOut: 'animate_out',
      animateNormal: 'normal',
      rewind: false,
      autoplayButtonOutput: false,
      preventActionWhenRunning: true,
      lazyload: true,
      lazyloadSelector: ".tns-lazy",
    });

    if (!slider) return;

    setTimeout(() => {
      addZero(slider, sliderContainer);
    }, 300);

    slider.events.on('transitionStart', () => {
      addZero(slider, sliderContainer);
    });

    bindArrows(left, right, slider, sliderContainer, true);
  }
};

document.addEventListener('DOMContentLoaded', () => {
  const slider = document.querySelector('[data-details-slider]');
  defaultSlider(
    slider,
    '[data-slider]',
    '[data-index-left]',
    '[data-index-right]',
    700,
  );
});

document.addEventListener('DOMContentLoaded', () => {
  const sliders = document.querySelectorAll('[data-slider-container]');
  sliders.forEach((slider) => {
    defaultSlider(
      slider,
      '[data-slider]',
      '[data-index-left]',
      '[data-index-right]',
    );
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const sliders = document.querySelectorAll('[data-slider-images]');
  sliders.forEach((slider) => {
    sliderImages(
      slider,
      '[data-slider]',
      '[data-index-left]',
      '[data-index-right]',
    );
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const slider = document.querySelector('[data-main-slider]');
  if (slider) {
    const targets = document.querySelectorAll('[data-slider-line]');
    targets.forEach((target) => {
      Splitting({ target: target, by: 'words' });
    });

    let words = slider.querySelectorAll('.word'),
        length = words.length,
        i, wrapper;

    for (i = 0; i < length; i++) {
      wrapper = document.createElement('span');
      wrapper.setAttribute('class', 'word-wrapper');
      words[i].parentNode.insertBefore(wrapper, words[i]);
      wrapper.appendChild(words[i]);
    }

    mainSlider(slider,
      '[data-slider]',
      '[data-index-left]',
      '[data-index-right]',
    );
  }
});