require('../scss/app.scss');

window.noZensmooth = true;

require('../fonts/Muller/Muller.css');
require('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro.css');

require('./common/goal');
require('./common/js_validation');
require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/map');
// require('./common/need-animation');
require('./common/floating-field');
require('./common/autosize');
require('./common/polyfills/webp');

// require('../vue/app');

require('./field/phone');
require('./field/show-password');
require('./field/custom-file-field');

require('../components/fields/js/filesfield');
require('../components/smart-tabs/smart-tabs');

require('fslightbox');

require('./catalog/animation');
require('./catalog/draggon-strip');
require('./catalog/color-switch');
require('./catalog/scrollable-menu');
require('./catalog/drop-down');
require('./catalog/sticky-menu-scroll');
require('./catalog/sticky-menu-hide');
require('./catalog/video');
require('./catalog/product-card');
require('./profile/confirm');
require('./filterForm/filterForm');
require('./header/header');
require('./header/sticky');
require('./header/menu-items-animation');
require('./main/categories');
require('./main/parallax-slider');
require('./accordion');
require('./success-redirect');
require('./sliders');
require('./switch-hide');
require('./display-animation');
require('./parallax');
require('./add');
require('./preloader/preloader');
require('./search/search');

require('./offices/index');
require('./tg-widget');